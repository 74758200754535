/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

declare global {
  interface Window {
    dom_ready?: boolean;
    fakeTotDevice?: boolean;
  }
}
import { logEvent } from '@/lib/utils/logger';
import { useEffect } from 'react';
import { getPointNo, getSkuList, isKiosk } from '@/lib/kioskCommands';
export default function ClientWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    console.log("页面已加载");
    const hostname = window.location.hostname;
    const isPrivateIP = /^(10\.|172\.(1[6-9]|2[0-9]|3[0-1])\.|192\.168\.|localhost$)/.test(hostname);
    if (typeof window.dom_ready === 'undefined') {
      window.dom_ready = true;
      if (isPrivateIP) {
        window.totDevice = {
          getSkuList: () => {
            return JSON.stringify(mockSkuList);
          },
          goBack: (sku?: number) => {
            alert('goBack:' + sku);
            return;
          }
        };
        window.fakeTotDevice = true;
        return;
      }
      const browserInfo = {
        version: process.env.NEXT_PUBLIC_BUILD_TIME,
        version_hash: process.env.NEXT_PUBLIC_BUILD_HASH?.substring(0, 7),
        userAgent: navigator.userAgent,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        vendor: navigator.vendor,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        screenAvailWidth: window.screen.availWidth,
        screenAvailHeight: window.screen.availHeight,
        screenColorDepth: window.screen.colorDepth,
        screenOrientation: window.screen.orientation?.type,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        windowOuterWidth: window.outerWidth,
        windowOuterHeight: window.outerHeight,
        pixelRatio: window.devicePixelRatio,
        platform: navigator.platform,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: (navigator as any).deviceMemory,
        maxTouchPoints: navigator.maxTouchPoints,
        language: navigator.language,
        languages: navigator.languages,
        cookieEnabled: navigator.cookieEnabled,
        online: navigator.onLine,
        connectionType: (navigator as any).connection?.type,
        connectionSpeed: (navigator as any).connection?.effectiveType,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        webdriver: navigator.webdriver,
        touchSupport: 'ontouchstart' in window,
        webGLSupport: !!window.WebGLRenderingContext,
        prefersDarkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
        performanceMemory: (window.performance as any)?.memory || null,
        userAgentData: (navigator as any).userAgentData ? {
          platform: (navigator as any).userAgentData.platform,
          mobile: (navigator as any).userAgentData.mobile,
          brands: (navigator as any).userAgentData.brands
        } : null

        // nonStandardWindowProperties: (() => {
        //   const standardProps = new Set([
        //     'window', 'self', 'document', 'name', 'location', 'history', 
        //     'customElements', 'locationbar', 'menubar', 'personalbar', 
        //     'scrollbars', 'statusbar', 'toolbar', 'status', 'closed',
        //     'frames', 'length', 'top', 'opener', 'parent', 'frameElement',
        //     'navigator', 'origin', 'external', 'screen', 'innerWidth', 
        //     'innerHeight', 'scrollX', 'pageXOffset', 'scrollY', 'pageYOffset',
        //     'screenX', 'screenY', 'outerWidth', 'outerHeight', 'devicePixelRatio',
        //     'event', 'clientInformation', 'styleMedia', 'performance',
        //     'crypto', 'indexedDB', 'webkitStorageInfo', 'chrome'
        //   ]);

        //   const nonStandard: Record<string, any> = {};
        //   for (const prop in window) {
        //     if (!standardProps.has(prop)) {
        //       try {
        //         const value = window[prop];
        //         // 处理不同类型的值
        //         if (value === null) {
        //           nonStandard[prop] = null;
        //         } else if (value === undefined) {
        //           nonStandard[prop] = undefined;
        //         } else if (typeof value === 'function') {
        //           nonStandard[prop] = String(value);
        //         } else if (typeof value === 'object') {
        //           try {
        //             nonStandard[prop] = JSON.stringify(value);
        //           } catch (e) {
        //             if(e) {}
        //             nonStandard[prop] = `[Object: ${typeof value}]`;
        //           }
        //         } else {
        //           nonStandard[prop] = value;
        //         }
        //       } catch (e) {
        //         nonStandard[prop] = `[Access Error: ${e instanceof Error ? e.message : String(e)}]`;
        //       }
        //     }
        //   }
        //   return nonStandard;
        // })(),
      };
      if (isKiosk()) {
        logEvent('dom_ready_sku_list_v4', {
          getPointNo: getPointNo(),
          sku_list: getSkuList(),
          browserInfo
          //totDevice: allFunctionsAndProperties()
        });
      } else {
        logEvent('dom_ready', {
          from: 'ClientWrapper',
          browserInfo
        });
      }
    }
    return () => {
      console.log("组件即将卸载");
    };
  }, []);
  return <>{children}</>;
}
const mockSkuList = [{
  "sku": 243,
  "name": "拿铁",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 822,
  "name": " 抹茶拿铁",
  "price": 0.01,
  "stdPrice": 13.8,
  "stat": 0
}, {
  "sku": 945,
  "name": "港式奶茶",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 947,
  "name": "美式咖啡",
  "price": 0.01,
  "stdPrice": 10.5,
  "stat": 1
}, {
  "sku": 948,
  "name": "太妃糖拿铁",
  "price": 0.01,
  "stdPrice": 16.5,
  "stat": 0
}, {
  "sku": 949,
  "name": "杨枝甘露",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 953,
  "name": "卡布奇诺",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 954,
  "name": "摩卡",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1101,
  "name": "玉米汁",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1124,
  "name": "经典美式咖啡",
  "price": 7.99,
  "stdPrice": 15,
  "stat": 0
}, {
  "sku": 1232,
  "name": "蜜桃咖啡拿铁",
  "price": 8,
  "stdPrice": 12,
  "stat": 0
}, {
  "sku": 1257,
  "name": "热美式",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1291,
  "name": "生椰拿铁",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1311,
  "name": "黄精桑葚茶",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1312,
  "name": "胖大海菊花茶",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1313,
  "name": "人参姜红茶",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1314,
  "name": "阿胶姜红茶",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}, {
  "sku": 1337,
  "name": "阿萨姆奶茶",
  "price": 0.01,
  "stdPrice": 0.01,
  "stat": 0
}];