/**
 * 全局配置文件
 */

const SERVICE_BASE_URL = 'https://amcbt.singou.net'
const PB_API_BASE_URL = SERVICE_BASE_URL + '/pb/api'
const RECOMMENDATION_API_BASE_URL = 'http://8.138.118.60:9000/v1/workflows'
//const RECOMMENDATION_PROXY_API_BASE_URL = '/drinkapi'

export const CONFIG = {
  // API 相关配置
  API: {
    BASE_URL: process.env.NEXT_PUBLIC_API_URL || 'http://xxxxxxx:9000',
    TIMEOUT: 10000, // 请求超时时间（毫秒）
    ENDPOINTS: {
      EVENT_COLLECTOR: `${PB_API_BASE_URL}/collections/collector/records`,
      RECOMMENDATION: `${RECOMMENDATION_API_BASE_URL}/run`,
      PAYMENT_TEST: `${SERVICE_BASE_URL}/ptest/ptest/test_pay`
    },
    TOKEN: process.env.NEXT_PUBLIC_API_TOKEN || 'Bearer app-xxxxxxxxx',
    RECOMMENDATION_TOKEN: `Bearer app-uhxy4Pra8ecjiSHul3fIbgrt`,
  },
  PAGE:{
    LEFT_MENU:true,
    RIGHT_MENU:false,
  },

  // 设备相关配置
  DEVICE: {
    // 设备状态码
    STATUS: {
      AVAILABLE: 1,    // 可用
      UNAVAILABLE: 0,  // 不可用
    },
    "windowWidth": 1384,
    "windowHeight": 911,
  },

  // SKU 相关配置
  SKU: {
    // SKU 状态
    STATUS: {
      ON_SALE: 1,     // 可售
      SOLD_OUT: 0,    // 售罄
    },
    // SKU 展示配置
    DISPLAY: {
      GRID_COLS: {
        SM: 4,        // 小屏幕一列
        MD: 4,        // 中等屏幕两列
        LG: 4,        // 大屏幕三列
      }
    }
  },

  // UI 相关配置
  UI: {
    THEME_COLORS: {
      PRIMARY: '#51AE93',    // 主色
      SECONDARY: '#AE3932',  // 次要色
      SUCCESS: '#22c55e',    // 成功色
      ERROR: '#ef4444',      // 错误色
      WARNING: '#f59e0b',    // 警告色
      BACKGROUND: '#FDFDF9',  // 背景色
    },
  },
}


// 类型定义
export type ConfigType = typeof CONFIG

// 导出默认配置
export default CONFIG 