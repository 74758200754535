/* eslint-disable @typescript-eslint/no-explicit-any */

import { CONFIG } from '@/app/config';

interface LogData {
    timestamp: string;
    page: string;
    [key: string]: any;  // 允许添加其他任意数据
  }
  
  export async function logEvent(eventName: string, data: Partial<LogData> = {}) {
    try {
      // 获取或生成 device_hash
      let deviceHash = localStorage.getItem('device_hash');
      if (!deviceHash) {
        deviceHash = Math.random().toString(36).substring(2) + Date.now().toString(36);
        localStorage.setItem('device_hash', deviceHash);
      }

      const response = await fetch(CONFIG.API.ENDPOINTS.EVENT_COLLECTOR, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event: eventName,
          data: JSON.stringify({
            timestamp: new Date().toISOString(),
            ...data
          }),
          device_hash: deviceHash
        })
      })
  
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
  
      console.log('Event logged successfully')
      return true
    } catch (error) {
      console.error('Failed to log event:', error)
      return false
    }
  }